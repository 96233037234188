import { slateNodesToInsertDelta, yTextToSlateElement } from "@slate-yjs/core"
import { useCallback, useEffect, useState } from "react"
import * as Y from "yjs"
import { compare } from "../fast-json-patch/duplex"
import { IsRichText, RichText } from "../rich-text/RichText"
import { Operation } from "../fast-json-patch/core"
import { AdoptYValue } from "./YProxy"

export namespace YTools {
    export function isArray(doc: Y.Doc) {
        return doc.getMap("type").get("root") === "array"
    }
    export function getRoot(doc: Y.Doc): Y.Array<any> | Y.Map<any> {
        return isArray(doc) ? doc.getArray("root") : doc.getMap("root")
    }

    export function loadDoc(doc: Y.Doc, root: any): any {
        doc.transact(() => {
            const type = doc.getMap("type")
            if (root instanceof Array) {
                type.set("root", "array")
                const yroot = doc.getArray("root")

                for (const item of root) {
                    yroot.push([valueToYValue(item)])
                }
                return
            } else {
                type.set("root", "map")
                const yroot = doc.getMap("root")
                for (const key in root) {
                    yroot.set(key, valueToYValue(root[key]))
                }
            }
        })
    }

    export function valueToYValue(value: any): Y.AbstractType<any> {
        if (value instanceof Array) {
            return arrayToYArray(value)
        } else if (value instanceof Object) {
            return objectToYValue(value)
        } else {
            return value
        }
    }

    export function arrayToYArray(arr: any[]): Y.Array<any> {
        const yarray = new Y.Array<any>()
        for (const item of arr) {
            yarray.push([valueToYValue(item)])
        }
        return yarray
    }

    export function objectToYValue(obj: any): Y.AbstractType<any> {
        if (IsRichText(obj)) {
            const text = new Y.XmlText()
            text.applyDelta(slateNodesToInsertDelta(obj.$RichText))
            return text
        }

        const map = new Y.Map()
        for (const key in obj) {
            map.set(key, valueToYValue(obj[key]))
        }
        return map
    }

    export function toJSON(doc: Y.Doc): any {
        return valueToJSON(YTools.getRoot(doc))
    }

    export function valueToJSON(value: Y.AbstractType<any>): any {
        if (value instanceof Y.Array) {
            return arrayToJSON(value)
        } else if (value instanceof Y.Map) {
            return mapToJSON(value)
        } else if (value instanceof Y.XmlText) {
            const elm = yTextToSlateElement(value)
            if (typeof elm === "object" && "children" in elm && !("type" in elm))
                return RichText(...(elm as any).children)

            if (elm instanceof Array) return RichText(...elm)
            else return RichText(elm as any)
        } else {
            return value
        }
    }

    export function arrayToJSON(arr: Y.Array<any>): any[] {
        const result: any[] = []
        arr.forEach((value) => {
            result.push(valueToJSON(value))
        })
        return result
    }

    export function mapToJSON(map: Y.Map<any>): any {
        const obj: any = {}
        map.forEach((value, key) => {
            const kv = valueToJSON(value)
            if (kv !== undefined) obj[key] = kv
        })
        return obj
    }

    export type Node = Y.Array<any> | Y.Map<any>

    export function set<T>(parent: Node, key: number | string, value: T) {
        if (parent instanceof Y.Array) {
            parent.delete(key as number, 1)
            parent.insert(key as number, [AdoptYValue(value)])
        } else {
            parent.set(key as string, AdoptYValue(value))
        }
        return value
    }

    export function get(parent: Node, key: number | string) {
        if (parent instanceof Y.Array) {
            return parent.get(key as number)
        } else if (parent instanceof Y.Map) {
            return parent.get(key as string)
        } else {
            throw new Error("Expected an Y.Array or Y.Map, got " + JSON.stringify(parent))
        }
    }
    export function getString(parent: Node, key: number | string): string {
        const str = get(parent, key)
        if (typeof str !== "string") throw new Error("Expected a string")
        return str
    }
    export function getStringOrUndefined(parent: Node, key: number | string): string | undefined {
        const str = get(parent, key)
        if (str === undefined) return
        if (typeof str !== "string") throw new Error("Expected a string")
        return str
    }

    export function getArray<T>(parent: Node, key: number | string): Y.Array<T> {
        const arr = get(parent, key)
        if (!(arr instanceof Y.Array)) throw new Error("Expected an Y.Array")
        return arr
    }
    export function getMap<T>(parent: Node, key: number | string): Y.Map<T> {
        const map = get(parent, key)
        if (!(map instanceof Y.Map)) throw new Error("Expected an Y.Map")
        return map
    }
    export function getNode(parent: Node, key: number | string): Node {
        const node = get(parent, key)
        if (!(node instanceof Y.Array || node instanceof Y.Map))
            throw new Error("Expected an Y.Array or Y.Map, got " + JSON.stringify(node))
        return node
    }

    export function deleteKey(parent: Node, key: number | string) {
        if (parent instanceof Y.Array) {
            parent.delete(key as number, 1)
        } else {
            parent.delete(key as string)
        }
    }

    /**
     * The encoding used to serialize Y.js payloads
     */
    export function uint8ArrayToBase64(uint8Array: Uint8Array): string {
        const chunkSize = 8192
        let binary = ""
        for (let i = 0; i < uint8Array.length; i += chunkSize) {
            binary += String.fromCharCode(...uint8Array.subarray(i, i + chunkSize))
        }
        return btoa(binary)
    }

    /**
     * The decoding used to deserialize Y.js payloads
     */
    export function base64ToUint8Array(base64: string) {
        const binaryString = atob(base64)
        const bytes = new Uint8Array(binaryString.length)
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i)
        }
        return bytes
    }

    /**
     * Compares the newDoc to the provided yDoc, and applies all differences as
     * operations to the yDoc. This is done using fast-json-patch.
     *
     * Returns the resulting JSON object.
     */
    export function applyDiff(yDoc: Y.Doc, newDoc: any, oldDoc?: any, origin?: any) {
        const root = YTools.getRoot(yDoc)
        oldDoc ??= valueToJSON(root)
        const operations = compare(oldDoc, newDoc)
        if (operations.length === 0) {
            return newDoc
        }

        applyOperations(yDoc, root, operations, origin)
        return newDoc
    }

    export function applyOperations(
        yDoc: Y.Doc,
        root: YTools.Node,
        operations: Operation[],
        origin?: any
    ) {
        yDoc.transact(() => {
            for (const operation of operations) {
                switch (operation.op) {
                    case "add":
                        {
                            const path = operation.path.split("/").slice(1)
                            let node: YTools.Node = root
                            for (let i = 0; i < path.length - 1; i++) {
                                node = YTools.get(node, path[i])
                            }
                            const value = YTools.valueToYValue(operation.value)

                            if (node instanceof Y.Array) {
                                node.insert(parseInt(path[path.length - 1]), [value])
                            } else if (node instanceof Y.Map) {
                                node.set(path[path.length - 1], value)
                            }
                        }
                        break

                    case "remove":
                        {
                            const path = operation.path.split("/").slice(1)
                            let node: YTools.Node = root
                            for (let i = 0; i < path.length - 1; i++) {
                                node = YTools.get(node, path[i])
                            }

                            if (node instanceof Y.Array) {
                                node.delete(parseInt(path[path.length - 1]), 1)
                            } else if (node instanceof Y.Map) {
                                node.delete(path[path.length - 1])
                            }
                        }
                        break
                    case "replace":
                        {
                            const path = operation.path.split("/").slice(1)
                            let node: YTools.Node = root
                            for (let i = 0; i < path.length - 1; i++) {
                                node = YTools.get(node, path[i])
                            }

                            const value = YTools.valueToYValue(operation.value)

                            YTools.set(node, path[path.length - 1], value)
                        }

                        break
                    default:
                        throw new Error(`Unsupported operation: ${operation.op}`)
                }
            }
        }, origin)
    }
}

/**
 * Returns a state that is derived from the state of a Y.Doc.
 *
 * When the Y.Doc is updated, the state will be recomputed. If any of the
 * dependencies change, the state will also be recomputed.
 */
export function useYDocDerivedState<T>(doc: Y.Doc, computeState: () => T, deps: any[]) {
    const [state, setState] = useState<T>(computeState)
    useEffect(() => {
        const observer = () => {
            setState(computeState())
        }
        doc.on("update", observer)
        return () => {
            doc.off("update", observer)
        }
    }, deps)

    return state
}

export function useYState(obj: YTools.Node, property: string) {
    const [localValue, setLocalValue] = useState(YTools.get(obj, property))
    const setValue = useCallback(
        (v: any) => {
            setLocalValue(v)
            YTools.set(obj, property, v)
        },
        [obj, property]
    )
    useEffect(() => {
        function handler() {
            setLocalValue(YTools.get(obj, property))
        }
        obj.observe(handler)
        handler()
        return () => {
            obj.unobserve(handler)
        }
    }, [obj, property])

    return [localValue, setValue, setLocalValue] as const
}
