const isNode = !!(
    typeof process === "object" &&
    typeof process.versions === "object" &&
    process.versions.node
)

/** Returns true if the current runtime environment is Node.js.
 *  Returns false if browser or some other non-node environment
 */
export function IsNode() {
    return isNode
}

export function AssertNode() {
    if (!IsNode()) {
        throw new Error(
            "This code is only meant for a Node.js environment. Did you accidentally import it?"
        )
    }
}
