// To avoid importing all of Y.js prematurely, this file contains the utilities needed
// for detecting whether a given object is a YProxy object

export const YProxyTarget = Symbol("proxy-target")

export function IsYProxy(obj: any) {
    return GetYProxyTarget(obj) !== undefined
}

export function GetYProxyTarget(obj: any) {
    return (obj && typeof obj === "object" && obj[YProxyTarget]) || undefined
}
