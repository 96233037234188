/*
 * This holds a general prupose structure for representing rich text, which is
 * compatible with the Slate.js editor.
 *
 * This can be used in model fields, whenever you want to store rich text that
 * can be manipulated using a WYSIWYG editor.
 */

export type RichTextSpan = {
    text: string
    bold?: boolean
    italic?: boolean
    underline?: boolean
    code?: boolean
    link?: string
}
export type Heading1 = {
    type: "Heading1"
    children: RichTextSpan[]
}
export type Heading2 = {
    type: "Heading2"
    children: RichTextSpan[]
}
export type Paragraph = {
    type: "Paragraph"
    children: RichTextSpan[]
}
export type OrderedList = {
    type: "OrderedList"
    children: ListItem[]
}
export type UnorderedList = {
    type: "UnorderedList"
    children: ListItem[]
}
export type ListItem = {
    type: "ListItem"
    children: (Paragraph | RichTextSpan)[]
}
export type CodeBlock = {
    type: "CodeBlock"
    children: RichTextSpan[]
}
export type BlockQuote = {
    type: "BlockQuote"
    children: RichTextElement[]
}

export type RichTextParentElement = RichTextElement | ListItem

export type RichTextElement =
    | Heading1
    | Heading2
    | Paragraph
    | OrderedList
    | UnorderedList
    | CodeBlock
    | BlockQuote

/**
 * Represents a rich text document.
 */
export type RichText = {
    /**
     * The root array of rich text elements.
     *
     * This property is used to identify the object as a rich text object during
     * serialization or conversion to other formats. This is a convention used
     * to avoid requiring full reflection info while serializing.
     */
    $RichText: RichTextElement[]
}

/**
 * Converts a list of rich text elements into a rich text document.
 */
export function RichText(...elements: (RichTextElement | string)[]): RichText {
    // Rich text must have at least one element
    if (elements.length === 0) return RichText("")
    return { $RichText: elements.map((e) => (typeof e === "string" ? Paragraph(e) : e)) }
}

/**
 * Checks if a value is a rich text document.
 */
export function IsRichText(value: any): value is RichText {
    return value && typeof value === "object" && "$RichText" in value
}

export function Heading1(...spans: RichTextSpan[]): Heading1
export function Heading1(text: string): Heading1
export function Heading1(...args: any[]): Heading1 {
    return { type: "Heading1", children: args.length === 1 ? [{ text: args[0] }] : args }
}
export function Heading2(...spans: RichTextSpan[]): Heading2
export function Heading2(text: string): Heading2
export function Heading2(...args: any[]): Heading2 {
    return { type: "Heading2", children: args.length === 1 ? [{ text: args[0] }] : args }
}

export function Paragraph(...spans: RichTextSpan[]): Paragraph
export function Paragraph(text: string): Paragraph
export function Paragraph(...args: any[]): Paragraph {
    return { type: "Paragraph", children: args.length === 1 ? [{ text: args[0] }] : args }
}
