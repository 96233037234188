import { TSQFunction } from "../../TSQ/TSQFunction"
import { OpaqueNumber, OpaqueString } from "../Opaque"
import { TypeValidator } from "./TypeValidator"
import { YearMonthDate } from "./YearMonthDate"

export type DateTimeUnit = "year" | "month" | "day" | "hour" | "minute" | "second" | "millisecond"

/**
 * @shared
 */
export type DateTimePrecision =
    | DateTimeUnit
    | {
          unit: DateTimeUnit
          /** How many of the units represents one step. Defaults to 1 if not specified */
          units?: number
      }

/**
 * Parses a string as documented in `@precision.md`
 */
export function ParseDateTimePrecision(s: string): DateTimePrecision | undefined {
    const parts = s.split(" ")
    const units = parts.length === 1 ? 1 : parseInt(parts[0])
    let unit = parts[parts.length - 1].toLowerCase()

    // allow both plural and singular
    if (unit.endsWith("s")) unit = unit.substring(0, unit.length - 1)

    switch (unit) {
        case "year":
        case "month":
        case "day":
        case "hour":
        case "minute":
        case "second":
        case "millisecond":
            return { unit, units }
    }

    throw new Error("Invalid DateString precision: " + JSON.stringify(s))
}

/** A Unix timestamp, the number of milliseconds elapsed since January 1, 1970
 *  00:00:00 UTC. Such a timestamp can be retrieved by calling Date.now() or
 *  Timestamp()
 *
 * For legacy reasons, this is stored in the database as `DOUBLE PRECISION`.
 * Since this is unconventional, it is recommended to use `PGTimestamp` for
 * storing timestamps in the database.
 */
export type Timestamp = OpaqueNumber<"Timestamp">
TypeValidator(Timestamp)
TSQFunction(Timestamp)
export function Timestamp(stamp?: number): Timestamp {
    if (stamp === undefined) return Date.now() as any
    return stamp as any
}

export type PGTimestamp = OpaqueString<"PGTimestamp">
TypeValidator(PGTimestamp)
TSQFunction(PGTimestamp)
/**
 * Represents a timestamp in ISO 8601 format, e.g. `2021-12-14T17:31:21Z`.
 *
 * When stored in the database (as a column in table), this is stored as a `TIMESTAMP` type.
 *
 * When stored as event data, this is stored as a string.
 *
 * When stored in the database (as a JSONB column), this is stored as a string.
 */
export function PGTimestamp(
    d: string | Date | number | DateString | PGTimestamp | Timestamp | YearMonthDate = Date.now()
): PGTimestamp {
    return new Date(d.valueOf()).toISOString() as any as PGTimestamp
}

/** Represents a date in ISO 8601 format, e.g. `2021-12-14T17:31:21Z`.
 *
 * This type is recommended for storing dates in Reactor and passing dates over the API.
 *
 * For legacy reasons, this is stored in the database as `TEXT`. Since this is unconventional,
 * it is recommended to use `PGTimestamp` for storing dates in the database.
 *
 * @shared
 */
export type DateString = OpaqueString<"DateString">
TypeValidator(DateString)
TSQFunction(DateString)
export function DateString(
    /** A string, date, number or Timestamp.
     *
     *  If omitted, it returns the date string corresponding to `Date.now()`.
     */
    d: string | Date | number | PGTimestamp | DateString | Timestamp | YearMonthDate = Date.now()
): DateString {
    return new Date(d.valueOf()).toISOString() as any
}

/** Formats the string in YYYY[-MM[-DD[Thh:mm[:ss[.000]]]]] format in local time, according to the
 * specified precision. This is the format expected by HTML's <input> tag, conveniently. */
DateString.toLocal = function (ds: DateString | PGTimestamp, precision: DateTimePrecision) {
    function pad(s: string | number) {
        s = s.toString()
        if (s.length === 1) return "0" + s
        return s
    }

    const unit = typeof precision === "string" ? precision : precision.unit

    const d = new Date(ds.valueOf())
    if (unit === "year") {
        return `${d.getFullYear().toString()}` as any
    } else if (unit === "month") {
        return `${d.getFullYear().toString()}-${pad(d.getMonth() + 1)}` as any
    } else {
        const date = `${d.getFullYear().toString()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}`

        if (unit === "day") {
            return date as any
        } else {
            const hourAndMinutes = pad(d.getHours()) + ":" + pad(d.getMinutes())

            if (unit === "minute") {
                return (date + "T" + hourAndMinutes) as any
            } else {
                const hoursAndMinutesAndSeconds = `${hourAndMinutes}:${pad(d.getSeconds())}`

                if (unit === "second") {
                    return (date + "T" + hoursAndMinutesAndSeconds) as any
                } else if (unit === "millisecond") {
                    let ms = d.getMilliseconds().toString()
                    while (ms.length < 3) ms = "0" + ms
                    return `${date}T${hoursAndMinutesAndSeconds}.${ms}` as any
                }
            }
        }
    }

    throw new Error("Invalid DateString precision: " + JSON.stringify(precision))
}

/**
 * Checks if date `a` is after date `b`.
 */
DateString.isAfter = function (
    a: DateString | number | string | Date | PGTimestamp | YearMonthDate,
    b: DateString | number | string | Date | PGTimestamp | YearMonthDate
) {
    return new Date(a.valueOf()) > new Date(b.valueOf())
}

/**
 * Checks if date `a` is before date `b`.
 */
DateString.isBefore = function (
    a: DateString | number | string | Date | PGTimestamp | YearMonthDate,
    b: DateString | number | string | Date | PGTimestamp | YearMonthDate
) {
    return new Date(a.valueOf()) < new Date(b.valueOf())
}

/**
 * Checks if date `a` is identical to date `b` (milliseconds are the same)
 */
DateString.isSame = function (
    a: DateString | number | string | Date | PGTimestamp | YearMonthDate,
    b: DateString | number | string | Date | PGTimestamp | YearMonthDate
) {
    return new Date(a.valueOf()).getTime() === new Date(b.valueOf()).getTime()
}

/**
 * Sort function for DateStrings, sorts ascending
 */
DateString.sortAscending = function (
    a: DateString | PGTimestamp | YearMonthDate,
    b: DateString | PGTimestamp | YearMonthDate
) {
    return new Date(a.valueOf()).getTime() - new Date(b.valueOf()).getTime()
}

/**
 * Sort function for DateStrings, sorts descending
 */
DateString.sortDescending = function (
    a: DateString | PGTimestamp | YearMonthDate,
    b: DateString | PGTimestamp | YearMonthDate
) {
    return new Date(b.valueOf()).getTime() - new Date(a.valueOf()).getTime()
}
