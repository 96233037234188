import { useMemo } from "react"
import * as Y from "yjs"
import { YTools } from "./YTools"
import { GetYProxyTarget, YProxyTarget } from "./YProxyTarget"

const proxies = new WeakMap<any, any>()

/**
 * Returns a proxy for a Y.Map or Y.Array object, that behaves similar to a
 * regular JS object or array, but uses the Yjs API under the hood.
 *
 * This is useful for e.g. React components that don't want to know about Yjs,
 * but still want to participate in WYSIWYG editing. This allows for mixing and
 * matching Yjs-aware and Yjs-unaware components when rendering a React
 * component tree.
 *
 * Use the `useYProxy` hook to get a proxy object for Yjs value in React.
 *
 * Use `YProxy.getTarget` to get the original Yjs object from a proxy object,
 * when reaching an editable node.
 *
 * Note that the resulting proxy object does not support all JS features, and
 * may not work with all libraries. It is intended for simple use-cases only,
 * and to be expanded as needed.
 *
 */
export function YProxy<T>(value: any): T {
    if (value instanceof Y.Map) {
        return YProxyMap(value)
    } else if (value instanceof Y.Array) {
        return YProxyArray(value) as T
    } else {
        return value
    }
}

/**
 * A React hook that returns a proxy object for a Y.Map or Y.Array
 * object, that behaves similar to a regular JS object or array, but
 * uses the Yjs API under the hood.
 *
 * See `YProxy` for more information.
 */
export function useYProxy<T>(
    /**
     * The Y.Map or Y.Array that contains the object you want to proxy.
     */
    obj: YTools.Node,
    /**
     * The property name of the object you want to proxy.
     */
    prop: string
): T {
    return useMemo(() => YProxy(YTools.get(obj, prop)), [obj, prop])
}

function YProxyMap<T>(yMap: Y.Map<any>): T {
    const existing = proxies.get(yMap)
    if (existing) {
        return existing
    }

    function get(target: any, prop: string | symbol) {
        if (prop === YProxyTarget) {
            return yMap
        }

        // Handle special object methods like keys, entries, values
        if (prop === "keys") {
            return () => Array.from(yMap.keys())
        }
        if (prop === "entries") {
            return () => Array.from(yMap.entries())
        }
        if (prop === "values") {
            return () => Array.from(yMap.values())
        }
        if (prop === Symbol.iterator) {
            return function* () {
                for (const [key, value] of yMap.entries()) {
                    yield [key, value]
                }
            }
        }
        if (prop === "toJSON") {
            return () => {
                const obj: any = {}
                for (const [key, value] of yMap.entries()) {
                    obj[key] = value
                }
                return obj
            }
        }

        // Handle dynamic property access
        if (typeof prop === "string") {
            const value = yMap.get(prop)
            if (value instanceof Y.Map) {
                return YProxyMap(value)
            } else if (value instanceof Y.Array) {
                return YProxyArray(value)
            } else {
                return value
            }
        }

        // Fallback for other properties
        return Reflect.get(target, prop)
    }

    const handler: ProxyHandler<any> = {
        ownKeys() {
            return Array.from(yMap.keys())
        },
        getOwnPropertyDescriptor(target, prop) {
            // Define property descriptors to make keys enumerable
            return {
                configurable: true,
                enumerable: true, // This is crucial for Object.keys
                value: get(target, prop),
            }
        },
        get,
        set(target, prop, value) {
            if (typeof prop === "string") {
                yMap.set(prop, AdoptYValue(value))
                return true
            }
            return false
        },
    }

    const proxy = new Proxy(yMap, handler) as T

    proxies.set(yMap, proxy)

    return proxy
}

function YProxyArray<T extends any[]>(yArray: Y.Array<any>): T {
    const existing = proxies.get(yArray)
    if (existing) {
        return existing
    }

    const proxy = new Proxy(yArray, {
        get(target, prop) {
            if (prop === YProxyTarget) {
                return yArray
            }

            if (prop === "map") {
                return (callback: (value: any, index: number, array: any[]) => any) => {
                    const raw = yArray.toArray()
                    const res: any[] = []
                    for (let i = 0; i < yArray.length; i++) {
                        const value = YProxy(yArray.get(i))
                        res.push(callback(value, i, raw))
                    }
                    return res
                }
            }

            if (prop === "splice") {
                return (start: number, deleteCount: number, ...items: any[]) => {
                    const deleted = yArray.slice(start, start + deleteCount)
                    if (deleteCount) yArray.delete(start, deleteCount)
                    if (items.length) yArray.insert(start, items.map(AdoptYValue))
                    return deleted
                }
            }

            if (prop === "find") {
                return (callback: (value: any, index: number, array: any[]) => boolean) => {
                    for (let i = 0; i < yArray.length; i++) {
                        const value = YProxy(yArray.get(i))
                        if (callback(value, i, yArray.toArray())) {
                            return value
                        }
                    }
                    return undefined
                }
            }
            if (prop === "some") {
                return (callback: (value: any, index: number, array: any[]) => boolean) => {
                    for (let i = 0; i < yArray.length; i++) {
                        const value = YProxy(yArray.get(i))
                        if (callback(value, i, yArray.toArray())) {
                            return true
                        }
                    }
                    return false
                }
            }
            if (prop === "every") {
                return (callback: (value: any, index: number, array: any[]) => boolean) => {
                    for (let i = 0; i < yArray.length; i++) {
                        const value = YProxy(yArray.get(i))
                        if (!callback(value, i, yArray.toArray())) {
                            return false
                        }
                    }
                    return true
                }
            }
            if (prop === "filter") {
                return (callback: (value: any, index: number, array: any[]) => boolean) => {
                    const res: any[] = []
                    for (let i = 0; i < yArray.length; i++) {
                        const value = YProxy(yArray.get(i))
                        if (callback(value, i, yArray.toArray())) {
                            res.push(value)
                        }
                    }
                    return res
                }
            }
            if (prop === "forEach") {
                return (callback: (value: any, index: number, array: any[]) => void) => {
                    for (let i = 0; i < yArray.length; i++) {
                        const value = YProxy(yArray.get(i))
                        callback(value, i, yArray.toArray())
                    }
                }
            }
            if (prop === "push") {
                return (...items: any[]) => {
                    yArray.insert(yArray.length, items.map(AdoptYValue))
                    return yArray.length
                }
            }
            if (prop === "pop") {
                return () => {
                    const index = yArray.length - 1
                    const value = yArray.get(index)
                    yArray.delete(index, 1)
                    return value
                }
            }
            if (prop === "shift") {
                return () => {
                    const value = yArray.get(0)
                    yArray.delete(0, 1)
                    return value
                }
            }
            if (prop === "unshift") {
                return (...items: any[]) => {
                    yArray.insert(0, items.map(AdoptYValue))
                    return yArray.length
                }
            }
            if (prop === "length") {
                return yArray.length
            }
            if (prop === "reduce") {
                return (
                    callback: (acc: any, value: any, index: number, array: any[]) => any,
                    initialValue: any
                ) => {
                    let acc = initialValue
                    for (let i = 0; i < yArray.length; i++) {
                        const value = YProxy(yArray.get(i))
                        acc = callback(acc, value, i, yArray.toArray())
                    }
                    return acc
                }
            }
            if (prop === "toJSON") {
                return () => yArray.toArray()
            }

            if (prop in Array.prototype) {
                return (...args: any[]) => {
                    const snapshot = yArray.toArray() // Convert Y.Array to a JS array
                    const result = (snapshot as any)[prop](...args) // Call the array method
                    return result
                }
            }

            // Access numeric indices
            const index = typeof prop === "string" ? Number(prop) : (prop as any as number)
            if (!Number.isNaN(index)) {
                const value = yArray.get(index)
                if (value instanceof Y.Map) {
                    return YProxyMap(value)
                } else if (value instanceof Y.Array) {
                    return YProxyArray(value)
                } else {
                    return value
                }
            }

            throw new Error(`YProxyArray: Unsupported property: ${prop.toString()}`)
        },
        set(target, prop, value) {
            const index =
                typeof prop === "string" ? parseInt(prop) : typeof prop === "number" ? prop : 0

            yArray.delete(index, 1)
            yArray.insert(index, [AdoptYValue(value)])
            return true
        },
    }) as any as T

    proxies.set(yArray, proxy)

    return proxy
}

/**
 * Adopts a value coming from the outside to a Y.js object if it isn't already
 */
export function AdoptYValue(value: any) {
    if (
        typeof value === "number" ||
        typeof value === "string" ||
        typeof value === "boolean" ||
        value === null ||
        value === undefined
    ) {
        // Do nothing, can be inserted directly
        return value
    }
    const target = GetYProxyTarget(value)

    if (target) return target

    if (value instanceof Y.Map || value instanceof Y.Array || value instanceof Y.XmlText) {
        return value
    }

    // Value is not a proxy, nor a Yjs object, so we need to convert it to a Yjs
    // object
    return YTools.valueToYValue(value)
}
