import React, { CSSProperties } from "react"
import { EditableText } from "../../../../packages/editing/EditableText"
import { FileToUrl, Video } from "../../../../reactor/Types/File"
import { Logo } from "../assets/Logo"
import { colors } from "../colors"
import { usePageContext } from "../Page"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { RangedNumber } from "../../../../reactor/Types/Opaque"
import { Section } from "../../../../packages/editing/Section"
import { useObserveYProxy } from "../../../../packages/y/useObserveYProxy"

Section(Hero)
function Hero(section: {
    backgroundVideo?: Video

    showLogo: boolean

    /** The section's subtext. */
    subtext: Localized<string>

    /**
     * @slider
     */
    backgroundOpacity?: RangedNumber<"Opacity", 0, 1>
    /**
     * @slider
     */
    backgroundBlur?: RangedNumber<"BlurPixels", 0, 100>
}) {
    const { venue } = usePageContext()
    const localize = useLocalize()

    useObserveYProxy(section, true)

    return (
        <div
            style={{
                backgroundColor: colors.darkPurple,
                color: "white",
                height: "60vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                width: "100%",
            }}
        >
            {(section.backgroundVideo || venue?.promoVideo) && (
                <video
                    src={FileToUrl((venue?.promoVideo ?? section.backgroundVideo) as any)}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    playsInline={true}
                    style={{
                        position: "absolute",
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        opacity: section.backgroundOpacity?.valueOf(),
                        filter: `blur(${section.backgroundBlur?.valueOf()}px)`,
                    }}
                />
            )}

            <div
                style={{
                    maxWidth: 700,
                    width: "60vw",
                    display: "flex",
                    flexDirection: "column",
                    zIndex: 10,
                }}
            >
                {section.showLogo && <Logo />}

                {venue ? (
                    <div style={payoffStyle}>{localize(venue.name)}</div>
                ) : (
                    <EditableText
                        obj={section}
                        prop="subtext"
                        isLocalized={true}
                        className="payoff"
                        style={payoffStyle}
                    />
                )}
            </div>
        </div>
    )
}

const payoffStyle: CSSProperties = {
    width: "100%",
    transform: "rotate(-6deg) translateY(max(-35px, -2.25vw))",
    transformOrigin: "center",
    textAlign: "center",
    textShadow: "0 0 10px rgba(0,0,0,0.5)",
    fontSize: `calc(min(54px, 0.25rem + 3.5vw))`,
}
