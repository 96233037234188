import { Lazy } from "../code-splitting/Lazy"
import { Outlet } from "react-router-dom"
import { CurrentLocaleProvider } from "../localization/client-side/CurrentLocaleContext"
import { DummySelectionContext, SelectionContext } from "./SelectionContext"
import { WebSocketContextProvider } from "../../reactor/Web"

export function EditableSiteLazy(props: { condition: boolean; plugins?: () => Promise<void> }) {
    return (
        <WebSocketContextProvider>
            <Lazy
                load={props.condition}
                target={async () => {
                    if (props.plugins) await props.plugins()
                    return (await import("./EditableSite")).EditableSite
                }}
                fallback={NonEditableSite}
            />
        </WebSocketContextProvider>
    )
}

function NonEditableSite() {
    return (
        <CurrentLocaleProvider>
            <SelectionContext.Provider value={DummySelectionContext}>
                <Outlet />
            </SelectionContext.Provider>
        </CurrentLocaleProvider>
    )
}
