import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useIsEditing } from "../../../../packages/editing/useIsEditing"
import { EditableText } from "../../../../packages/editing/EditableText"
import { GetType } from "../../../../reactor/ReflectionInfo"
import { Image, ImageToCSS, ImageToUrl } from "../../../../reactor/Types/File"
import { LogoIcon } from "../assets/LogoIcon"
import { MenuBurger } from "../assets/MenuBurger"
import { GetPageDto, GetPageLocales1Dto, OpeningInfo } from "../client"
import { colors } from "../colors"
import { PartyVenue } from "./party-booker/PartyContext"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../../packages/localization/client-side/useLocalize"
import { LocaleKey } from "../../../../packages/localization/Locale"
import { OpeningHoursText } from "./VenueInfo"
import { Localized } from "../../../../packages/localization/Localized"
import { usePageContext } from "../Page"
import { GroupBy } from "../../../../reactor/Helpers"
import { Section } from "../../../../packages/editing/Section"
import { useSelectionContext } from "../../../../packages/editing/SelectionContext"

Section(Menu)
function Menu(section: {
    chooseAVenue: Localized<string>
    venuePickerReplacement?: Localized<string>
}) {
    const page = usePageContext()
    const [showMenu, setShowMenu] = useState(false)
    const localize = useLocalize()

    function toggleMenu() {
        setShowMenu(!showMenu)
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",

                backgroundColor: colors.darkPurple,
                color: "white",
                fontFamily: "GT-Haptik-Medium",
                alignItems: "center",
                fontSize: 18,
                padding: 24,
                width: "100%",
            }}
        >
            <Link style={{ color: "white" }} to={`/${page.locale}/${page.venue?.slug ?? ""}`}>
                <LogoIcon />
            </Link>
            <div
                style={{
                    height: 40,
                    width: 1,
                    backgroundColor: "white",
                    marginLeft: 32,
                    marginRight: 32,
                }}
            />
            {page && !section.venuePickerReplacement ? (
                <VenuePicker
                    section={section}
                    slug={page.page.slugs[0]}
                    options={page.venues}
                    current={page.venues.find((v) => v.id === page.venue?.id)}
                />
            ) : (
                <div style={{ flex: 1 }}>
                    {section.venuePickerReplacement
                        ? localize(section.venuePickerReplacement)
                        : undefined}
                </div>
            )}
            {page.venue ? (
                <OpenTodayView page={page} openToday={page.venue.openToday} />
            ) : (
                <div style={{ flex: 1 }} />
            )}
            <MenuButtonWithPinnedPages data={page} toggleMenu={toggleMenu} />
            <SlideInMenu data={page} showMenu={showMenu} toggleMenu={toggleMenu} />
        </div>
    )
}

function OpenTodayView({
    page,
    openToday,
}: {
    page: { openTodayText: Localized<string> }
    openToday: OpeningInfo
}) {
    const localized = useLocalize()
    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                marginLeft: 32,
            }}
        >
            <EditableText
                className="min-width-md"
                style={{ marginRight: 8 }}
                obj={page}
                prop="openTodayText"
                isLocalized={true}
            />
            <div className="min-width-md">{OpeningHoursText(openToday, localized)}</div>
        </div>
    )
}

function LocalePicker({ data }: { data: GetPageLocales1Dto[] }) {
    return (
        <div style={{ display: "flex", flexDirection: "row", marginRight: 16 }}>
            {data.map((l) => (
                <LocaleButton key={l.locale.valueOf()} code={l.locale} flagImage={l.image} />
            ))}
        </div>
    )
}

function LocaleButton({ code, flagImage }: { code: LocaleKey; flagImage?: Image }) {
    const locale = useCurrentLocale()
    const { page, venue } = usePageContext()

    return (
        <Link to={`/${code.valueOf()}/${venue ? venue.slug + "/" : ""}${page.slugs[0] ?? ""}`}>
            <div
                style={{
                    cursor: "pointer",
                    width: 24,
                    height: 24,
                    backgroundImage: flagImage ? ImageToCSS(flagImage, { height: 24 }) : undefined,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    borderRadius: 32,
                    backgroundColor: "#ddd",
                    margin: 4,
                    opacity: locale === code ? 1 : 0.75,
                    border: `1px solid ${locale !== code ? "#fff" : "#000"}`,
                }}
            />
        </Link>
    )
}

function MenuButtonWithPinnedPages({
    data,
    toggleMenu,
}: {
    data: GetPageDto
    toggleMenu: () => void
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
            }}
        >
            {data.buttons
                ?.filter((b) => b.visibleInMenuBar)
                .map((b, i) => (
                    <Link
                        key={b.id.valueOf()}
                        className="min-width-lg"
                        style={{ color: "white", marginLeft: i > 0 ? 24 : 0 }}
                        to={b.link}
                    >
                        <EditableText obj={b} prop="title" isLocalized={true} />
                    </Link>
                ))}
            {data.groupButtons?.map((b) => (
                <PageGroupButton
                    key={b.id?.valueOf()}
                    name={b.title}
                    options={b.buttons.map((x) => ({ text: x.title, link: x.link }))}
                />
            ))}
            <div
                className="min-width-lg"
                onClick={toggleMenu}
                style={{ paddingLeft: 24, paddingRight: 24, cursor: "pointer" }}
            >
                <EditableText obj={data} prop="menuButtonText" isLocalized={true} />
            </div>
            <div style={{ cursor: "pointer" }} onClick={toggleMenu}>
                <MenuBurger />
            </div>
        </div>
    )
}

function SlideInMenu({
    data,
    showMenu,
    toggleMenu,
}: {
    data: GetPageDto
    showMenu: boolean
    toggleMenu: () => void
}) {
    const { setSelectedObject } = useSelectionContext()
    const editing = useIsEditing()
    return (
        <div
            onClick={
                editing
                    ? (e) => {
                          e.stopPropagation()
                          setSelectedObject(
                              data,
                              GetType("GetMenuDto"),
                              GetType("PutMenuRequestBodyDto")
                          )
                      }
                    : undefined
            }
            style={{
                display: "flex",
                flexDirection: "row",
                pointerEvents: showMenu ? undefined : "none",
                position: "fixed",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                backgroundColor: showMenu ? "#000000BB" : "transparent",
                transition: "all 0.5s ease",
                zIndex: 100,
                overflow: "hidden",
            }}
        >
            <div style={{ flex: 1 }} onClick={toggleMenu} />
            <div
                style={{
                    background: "white",
                    width: 480,
                    transform: showMenu ? "translateX(0px)" : "translateX(480px)",
                    transition: "all 0.35s ease",
                    height: "100%",
                    backgroundColor: colors.backgroundGray,
                    padding: 64,
                    color: colors.darkPurple,
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                }}
            >
                <div style={{ position: "absolute", top: 32, left: 64 }}>
                    {data.locales ? <LocalePicker data={data.locales} /> : undefined}
                </div>
                <div
                    onClick={toggleMenu}
                    style={{
                        position: "absolute",
                        top: 32,
                        right: 64,
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <EditableText
                        obj={data}
                        prop="sideMenuClose"
                        defaultText="Close"
                        isLocalized={true}
                    />
                    <svg
                        style={{ marginLeft: 8 }}
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="1"
                            y="15.1406"
                            width="20"
                            height="3"
                            rx="1.5"
                            transform="rotate(-45 1 15.1406)"
                            fill="#2A134E"
                        />
                        <rect
                            width="20"
                            height="3"
                            rx="1.5"
                            transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 17.2617 15.1406)"
                            fill="#2A134E"
                        />
                    </svg>
                </div>
                <div style={{ flex: 1 }}>
                    {data.buttons
                        ?.filter((b) => b.visibleInSlidingMenu)
                        .map((b) => (
                            <Link key={b.id.valueOf()} to={b.link} onClick={toggleMenu}>
                                <div
                                    style={{
                                        marginTop: 24,
                                        marginBottom: 12,
                                        fontSize: 24,
                                        fontWeight: 500,
                                        color: colors.darkPurple,
                                        lineHeight: 1.2,
                                        fontFamily: "GT-Haptik-Black,sans-serif",
                                    }}
                                >
                                    <EditableText obj={b} prop="title" isLocalized={true} />
                                </div>
                            </Link>
                        ))}
                </div>
                {data.sideMenuLargeLogo && (
                    <img
                        src={ImageToUrl(data.sideMenuLargeLogo)}
                        style={{ width: "100%", marginTop: 32, objectPosition: "center" }}
                    />
                )}
            </div>
        </div>
    )
}

export function VenuePicker({
    section,
    options,
    current,
    slug,
    prefix,
}: {
    section: { chooseAVenue: Localized<string> }
    options: PartyVenue[]
    current?: PartyVenue
    slug: string
    prefix?: string
}) {
    const localize = useLocalize()
    const currentCountry = current?.country
    const countries = GroupBy(options, (x) => x.country)

    // Move current country to the top
    const currentCountryIndex = countries.findIndex((x) => x.key === currentCountry)
    const currentCountryEntry = countries[currentCountryIndex]
    if (currentCountryEntry) {
        countries.splice(currentCountryIndex, 1)
        countries.unshift(currentCountryEntry)
    }

    return (
        <div className="dropdown">
            <button
                role="menu"
                className="btn btn-secondary dropdown-toggle"
                style={{
                    background: "transparent",
                    border: "none",
                    padding: 0,
                    margin: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                    fontSize: 18,
                }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {prefix && <div style={{ marginRight: 8 }}>{prefix}</div>}
                {current ? (
                    localize(current.name)
                ) : (
                    <EditableText
                        obj={section}
                        prop="chooseAVenue"
                        defaultText="Choose a venue"
                        isLocalized={true}
                    />
                )}
                <div style={{ width: 8 }} />
            </button>

            <ul className="dropdown-menu">
                {countries.map((country) => (
                    <React.Fragment key={country.key}>
                        <h6
                            style={{
                                fontSize: 12,
                                marginLeft: 16,
                                marginRight: 16,
                                paddingTop: 16,
                                paddingBottom: 4,
                                color: colors.grey5,
                                borderBottom: "1px solid #eee",
                            }}
                        >
                            {country.key}
                        </h6>
                        {country.values.map((opt, index) => (
                            <li key={opt.id.valueOf()}>
                                <Link
                                    to={`${opt.link}/${slug}`}
                                    className={`dropdown-item${
                                        " " + (current?.id === opt.id ? "black" : "")
                                    }`}
                                    style={{ color: colors.darkPurple }}
                                >
                                    {localize(opt.name)}
                                </Link>
                            </li>
                        ))}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    )
}

export function PageGroupButton({
    options,
    name,
}: {
    options: { text: Localized<string>; link: string }[]
    name: Localized<string>
}) {
    const localize = useLocalize()
    return (
        <div className="dropdown min-width-lg" style={{ marginLeft: 16 }}>
            <button
                role="menu"
                className="btn btn-secondary dropdown-toggle"
                style={{
                    background: "transparent",
                    border: "none",
                    padding: 0,
                    margin: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                    fontSize: 18,
                }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {localize(name)}
                <div style={{ width: 8 }} />
            </button>

            <ul className="dropdown-menu">
                {options.map((opt, index) => (
                    <li key={opt.link}>
                        <a href={opt.link} className="dropdown-item">
                            {localize(opt.text)}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}
