import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Uuid } from "../Types/Primitives/Uuid"
import { Assert } from "../Helpers"
import {
    IsResourceNotificationType,
    type ResourceNotificationType,
} from "../Server/ResourceNotification"

export type WebSocketSubscriber = {
    /**
     * The name of the resource in the Layer API to listen for changes in.
     */
    res: string
    /**
     * The primary key of the item to listen for changes in. If not provided, the
     * entire resource will be listened to.
     */
    key?: string
    /**
     * The layer to listen for changes in. If not provided, the base layer
     * (commited changes) will be listened to.
     */
    layer?: string
    /**
     * The callback to call when the item changes.
     */
    onChanged: (type: ResourceNotificationType) => void
}

export type WebSocketStatus = "connecting" | "connected" | "disconnected"

export type WebSocketContext = {
    addSubscriber: (s: WebSocketSubscriber) => void
    removeSubscriber: (s: WebSocketSubscriber) => void
    status: WebSocketStatus
    /**
     * A unique identifier for this connection. If the connection is lost and
     * reconnected, this key will change. The client should probably reset all
     * temporal assumptions about the state of things on the server, as we may
     * have lost some messages.
     *
     * If `undefined`, the connection has been temporarily lost.
     */
    connectionKey: Uuid | undefined
}

export const WebSocketContext = createContext<WebSocketContext | undefined>(undefined)

let reconnectBackoff = 0.25

/**
 * Manages a global websocket connection to the Reactor backend.
 *
 * The connection is created lazily upon the first subscription, and will automatically reconnect if the
 */
export function WebSocketContextProvider({ children }: { children: React.ReactNode }) {
    const [connectionKey, setConnectionKey] = useState<Uuid | undefined>(Uuid())
    const [status, setStatus] = useState<WebSocketStatus>("disconnected")
    const [subscribers] = useState<WebSocketSubscriber[]>([])
    const socketPromise = useRef<
        { socket: Promise<WebSocket>; resolve: (socket: WebSocket) => void } | undefined
    >(undefined)
    const [socket, setSocket] = useState<WebSocket | undefined>(undefined)

    function onMessage({ data }: { data: string }) {
        const msg = JSON.parse(data.toString())
        if (typeof msg === "object" && "changed" in msg) {
            const type: ResourceNotificationType = IsResourceNotificationType(msg.type)
                ? msg.type
                : "change"
            for (const { res, key, onChanged } of subscribers) {
                if (msg.changed === res && (key === undefined || msg.key === key)) {
                    onChanged(type)
                }
            }
        }
    }

    function onClose(this: WebSocket) {
        this.removeEventListener("open", onOpen)
        this.removeEventListener("message", onMessage)
        this.removeEventListener("close", onClose)
        this.removeEventListener("error", onClose)
        setConnectionKey(undefined)

        reconnectWithBackoff()
    }
    function reconnectWithBackoff() {
        socketPromise.current = undefined
        setSocket(undefined)
        // automatically reconnect, with exponential backoff
        setTimeout(connect, reconnectBackoff)
        reconnectBackoff = Math.min(reconnectBackoff * 2, 3000)
    }

    function onOpen(this: WebSocket) {
        this.addEventListener("message", onMessage)
        this.addEventListener("close", onClose)
        socketPromise.current?.resolve(this)
        this.send(JSON.stringify({ clientId: useChangeNotifications.clientId }))
        setSocket(this)
        setStatus("connected")
        setConnectionKey((old) => {
            if (old) return old
            return Uuid()
        })
    }

    const connect = useCallback(async () => {
        if (socketPromise.current) return await socketPromise.current.socket
        let resolve: ((socket: WebSocket) => void) | undefined
        const promise = new Promise<WebSocket>((r) => {
            resolve = r
        })

        socketPromise.current = { socket: promise, resolve: Assert(resolve!) }

        setStatus("connecting")
        const { getWebSocketTicketForStudioUser } = await import("../../studio/client")

        try {
            const ticket = await getWebSocketTicketForStudioUser()
            const proto = window.location.protocol === "https:" ? "wss:" : "ws:"
            const host = window.location.hostname
            let port = window.location.port
            if (port === "3001") port = "3000"
            const newSocket = new WebSocket(
                `${proto}//${host}:${port}/api/studio/documents/changed?ticket=${ticket.ticket}`
            )

            if (newSocket.readyState !== newSocket.OPEN) {
                newSocket.addEventListener("open", onOpen)
            } else {
                onOpen.call(newSocket)
            }
        } catch (e) {
            reconnectWithBackoff()
            return promise
        }

        return promise
    }, [])

    const addSubscriber = useCallback(
        async (s: WebSocketSubscriber) => {
            subscribers.push(s)
            const socket = await connect()
            socket.send(JSON.stringify({ subscribe: s.res, key: s.key, layer: s.layer }))
        },
        [connect, subscribers]
    )

    const removeSubscriber = useCallback(
        async (s: WebSocketSubscriber) => {
            const idx = subscribers.indexOf(s)
            if (idx !== -1) subscribers.splice(idx, 1)
            const socket = await connect()
            socket.send(JSON.stringify({ unsubscribe: s.res, key: s.key, layer: s.layer }))
        },
        [connect, subscribers]
    )

    const context = useMemo(
        () => ({ addSubscriber, removeSubscriber, status, connectionKey }),
        [addSubscriber, removeSubscriber, status, connectionKey]
    )

    // Ping the server every 30 seconds to keep the connection alive
    useEffect(() => {
        if (!socket) return

        const pingInterval = setInterval(() => {
            if (socket.readyState === socket.OPEN) {
                socket.send(JSON.stringify({ ping: true }))
            }
        }, 30_000)

        return () => clearInterval(pingInterval)
    }, [socket])

    return <WebSocketContext.Provider value={context}>{children}</WebSocketContext.Provider>
}

/**
 * Subscribes to change notifications for a collection or endpoint.
 *
 */
export function useChangeNotifications(
    resource: string,
    /**
     * The key to listen for changes in. If not provided, the entire
     * resource will be listened to.
     *
     * For collections, this is the primary key of the item.
     *
     * For endpoints, this is the stringified arguments to the endpoint.
     */
    key: string | undefined,

    /**
     * The layer to listen for changes in. If not provided, the base layer
     * (commited changes) will be listened to.
     */
    layer: string | undefined,

    onChanged: (type: ResourceNotificationType) => void,
    /** Enables or disables this hook, without changing the number of hooks
     * created in the React render. */
    canUseChangeNotifications = true
) {
    const wsc = useContext(WebSocketContext)

    // Listen for Change Stream
    useEffect(() => {
        if (!canUseChangeNotifications) return
        if (!wsc) throw new Error("WebSocketContext not found " + resource)

        const sub = {
            res: resource,
            key,
            layer,
            onChanged,
        }

        wsc.addSubscriber(sub)

        return () => {
            wsc.removeSubscriber(sub)
        }
    }, [resource, key, layer, onChanged, wsc, canUseChangeNotifications])
}

useChangeNotifications.clientId = Uuid()
