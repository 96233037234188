import React from "react"
import { Modal } from "../../packages/modal/Modal"
import { ChipWidget } from "../../packages/widgets/ChipWidget"
import { FunctionDesc, GetReflectionInfo } from "../../reactor"
import { server } from "../../server"
import { defaults } from "../client"
import { RButton } from "./Buttons"
import { Strings } from "../../packages/localization/client-side/Dictionary"

/**
 * Calls an endpoint with soft error handling.
 *
 * Soft errors are errors that may be ignored by users with the right
 * permissions.
 *
 * This function implements the header-based soft error handling protocol
 * described in `docs/Soft Errors.md`.
 *
 * This function returns the payload of the response if the response is
 * eventually successful.
 *
 * If the call resulted in a hard error, the error is thrown.
 *
 * If the call ended in a cancellation by the user due to a soft error, the
 * promise resolves to the string `"cancel"`.
 */
export async function CallEndpointWithSoftErrors(
    funcDescOrName: FunctionDesc | string,
    args: any[],
    ignoredSoftErrors: string[]
): Promise<"cancel" | { response: any }> {
    const func =
        typeof funcDescOrName === "string"
            ? GetReflectionInfo().functions.find((f) => f.name === funcDescOrName)
            : funcDescOrName
    if (!func) throw new Error("No function")
    const { verb, path } = func
    if (!verb) throw new Error(`No verb specified for ${func.name}`)
    if (!path) throw new Error(`No path specified for ${func.name}`)

    const pathSegments = path.split("/").filter((p) => !!p)
    const partArgNames = pathSegments.filter((p) => p.startsWith(":")).map((p) => p.slice(1))

    const bodyArg = func.args.find((a) => a.name === func.body)
    const body = bodyArg ? args[func.args.findIndex((a) => a.name === func.body)] : undefined

    const pathParts = path.split("/").map((p, i) => {
        if (!p.startsWith(":")) return p
        const argIndex = func.args.findIndex((a) => a.name === p.slice(1))
        if (argIndex === -1) throw new Error(`Path segment ${p} not found in args`)
        return args[argIndex]
    })

    const queryArgs = func.args
        .slice()
        .map((a, index) => {
            return {
                name: a.name,
                value: args[index],
                keep: !partArgNames.includes(a.name) && a.name !== func.body,
            }
        })
        .filter((a) => a.keep)

    const url = `${server()}/api${pathParts.join("/")}?${queryArgs
        .map((q) => `${q.name}=${q.value}`)
        .join("&")}`

    const res = await fetch(url, {
        method: func.verb,
        headers: {
            ...defaults.headers,
            "Reactor-Ignore-Soft-Errors": ignoredSoftErrors.join(","),
        },
        body: JSON.stringify(body),
    })

    if (res.status >= 200 && res.status < 300) {
        return { response: await res.json() }
    } else {
        const error = await res.json()
        const softError = res.headers.get("Reactor-Soft-Error")

        if (softError) {
            const modalRes = await Modal<"cancel" | string[]>((close) => (
                <div
                    style={{
                        padding: 20,
                        backgroundColor: "#fff",
                        borderRadius: 8,
                        maxWidth: 600,
                    }}
                >
                    <ChipWidget
                        value={{
                            color: "warning",
                            icon: "exclamation-triangle",
                            text: "Warning",
                            type: "Chip",
                        }}
                    />
                    <div style={{ marginTop: 32 }}>
                        <b>{error.detail}</b>
                    </div>
                    <div style={{ marginTop: 32 }}>
                        {Strings["Are you sure you want to continue?"]}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            marginTop: 32,
                        }}
                    >
                        <RButton
                            variant="primary"
                            onClick={() => {
                                close("cancel")
                            }}
                        >
                            {Strings.Cancel}
                        </RButton>
                        <RButton
                            variant="danger"
                            onClick={() => {
                                ignoredSoftErrors.push()
                                close([...ignoredSoftErrors, softError])
                            }}
                        >
                            {Strings["Ignore and continue"]}
                        </RButton>
                    </div>
                </div>
            ))
            if (modalRes === "cancel") {
                return "cancel"
            } else if (modalRes instanceof Array) {
                return await CallEndpointWithSoftErrors(func, args, modalRes)
            }
        }

        throw error
    }
}
