import { Link } from "react-router-dom"
import { css } from "@emotion/react"
import { EditableArray } from "../../../../packages/editing/EditableArray"
import { useIsEditing } from "../../../../packages/editing/useIsEditing"
import { EditableText } from "../../../../packages/editing/EditableText"
import { Localized } from "../../../../packages/localization/Localized"
import { GetType } from "../../../../reactor/ReflectionInfo"
import { ImageToCSS } from "../../../../reactor/Types/File"
import { Logo } from "../assets/Logo"
import { SocialLink } from "../client"
import { colors } from "../colors"
import { usePageContext } from "../Page"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../../packages/localization/client-side/useLocalize"
import { Section } from "../../../../packages/editing/Section"
import { useSelectionContext } from "../../../../packages/editing/SelectionContext"
import { responsiveCss } from "../css"

Section(Footer)
function Footer(section: {}) {
    const page = usePageContext()
    const localize = useLocalize()
    const locale = useCurrentLocale()
    const { setSelectedObject } = useSelectionContext()
    const editing = useIsEditing()
    const venueAddress =
        page.venue?.streetAddress && page.venue?.postalCode
            ? `Funplays, ${page.venue?.streetAddress}, ${page.venue?.postalCode}`
            : null
    return (
        <div
            onClick={
                editing
                    ? (e) => {
                          setSelectedObject(page, GetType("GetPageDto"))
                          e.stopPropagation()
                      }
                    : undefined
            }
            css={css(
                {
                    backgroundColor: colors.darkPurple,
                    color: colors.white,
                    padding: 40,
                    display: "grid",
                    gridTemplateAreas: '"lgo lgo" "scl lks" "ftn ftn"',
                    gridTemplateColumns: "1fr 1fr",
                    gap: 40,
                    a: {
                        color: colors.white,
                        textDecoration: "underline",
                    },
                },
                responsiveCss("min", "md", {
                    gridTemplateAreas: '"scl lgo lks" "scl ftn lks"',
                    gridTemplateColumns: "1fr 2fr 1fr",
                    gap: 50,
                    padding: 80,
                })
            )}
        >
            <div css={css({ gridArea: "scl" }, responsiveCss("min", "md", { paddingTop: 34 }))}>
                {page.socialLinks && <SocialLinks links={page.socialLinks} section={page} />}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gridArea: "lgo",
                    textAlign: "center",
                }}
            >
                <div
                    css={css(
                        { maxWidth: 314, marginBottom: 32 },
                        responsiveCss("min", "md", { marginBottom: 40 })
                    )}
                >
                    <Logo />
                </div>
                <EditableText obj={page} prop="footerText" isLocalized={true} isMarkdown={true} />
            </div>
            <div style={{ textAlign: "center", gridArea: "ftn" }}>
                {!!venueAddress && (
                    <div
                        css={css(
                            { marginBottom: 4 },
                            responsiveCss("min", "md", { marginBottom: 8 })
                        )}
                    >
                        {venueAddress}
                    </div>
                )}
                <EditableText obj={page} prop="footNote" isLocalized={true} isMarkdown={true} />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "flex-end",
                    textAlign: "right",
                    width: "100%",
                }}
                css={css({ gridArea: "lks" }, responsiveCss("min", "md", { paddingTop: 34 }))}
            >
                {page.footerLinks?.map((l) =>
                    l.link.startsWith("http") ? (
                        <a
                            key={l.id.valueOf()}
                            href={l.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={css(
                                { marginBottom: 8, ":last-of-type": { marginBottom: 0 } },
                                responsiveCss("min", "md", { marginBottom: 16 })
                            )}
                        >
                            {localize(l.text)}
                        </a>
                    ) : (
                        <Link
                            key={l.id.valueOf()}
                            to={`/${locale}${l.link}`}
                            css={css(
                                { marginBottom: 8, ":last-of-type": { marginBottom: 0 } },
                                responsiveCss("min", "md", { marginBottom: 16 })
                            )}
                        >
                            {localize(l.text)}
                        </Link>
                    )
                )}
            </div>
        </div>
    )
}

function SocialLinks({
    links,
    section,
}: {
    links: SocialLink[]
    section: { followUsOn: Localized<string> }
}) {
    const { country } = usePageContext()
    const editing = useIsEditing()
    return (
        <div
            style={{
                position: "relative",
                border: editing ? "1px dashed white" : undefined,
            }}
        >
            {editing && country ? (
                <div style={{ position: "absolute", top: -20, fontSize: 12 }}>
                    Social links for {country.name}
                </div>
            ) : undefined}

            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <EditableText
                    obj={section}
                    prop="followUsOn"
                    isLocalized={true}
                    defaultText="Follow us"
                />
                <div
                    css={css(
                        { display: "flex", flexDirection: "row", marginTop: 18 },
                        responsiveCss("min", "md", { marginTop: 24 })
                    )}
                >
                    <EditableArray arr={links} direction="row" itemTypeName="SocialLink">
                        {(s) => (
                            <a href={s.link}>
                                <div
                                    key={s.id.valueOf()}
                                    css={css(
                                        {
                                            backgroundColor: colors.pinkFlirt,
                                            backgroundImage: s.icon
                                                ? ImageToCSS(s.icon)
                                                : undefined,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            width: 48,
                                            height: 48,
                                            borderRadius: 48,
                                            marginRight: 12,
                                            backgroundSize: "24px 24px",
                                        },
                                        responsiveCss("min", "md", {
                                            width: 56,
                                            height: 56,
                                            borderRadius: 56,
                                            marginRight: 16,
                                            backgroundSize: "32px 32px",
                                        })
                                    )}
                                />
                            </a>
                        )}
                    </EditableArray>
                </div>
            </div>
        </div>
    )
}
