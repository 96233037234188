import { useEffect, useState } from "react"
import { GetYProxyTarget } from "./YProxyTarget"
import type * as Y from "yjs"

/**
 * Observes an YProxy, invalidating the component whenever the array or map
 * changes.
 *
 * This hook lazily loads Y.js, so in non-editing mode, the component will not
 * observe the proxy, nor load Y.js.
 *
 * If we are currently in edit mode, the hook will return the currently observed
 * target.
 */
export function useObserveYProxy(proxy: any, deep = false) {
    const target = GetYProxyTarget(proxy)
    useObserveYObject(target, deep)
    return target
}

/**
 * Observes a Y.Array or Y.Map, invalidating the component whenever the array or
 * map changes.
 *
 * This hook lazily loads Y.js when needed. If the target is undefined, the
 * component will not observe the target, nor load Y.js.
 *
 */
export function useObserveYObject(target: Y.Map<any> | Y.Array<any> | undefined, deep = false) {
    const [, setVersion] = useState({})
    const editing = !!target

    useEffect(() => {
        if (!target) return
        let unmounted = false
        let observing = false
        function onChange() {
            setVersion({})
        }

        async function observe() {
            const Y = await import("yjs")
            if (target instanceof Y.Array || target instanceof Y.Map) {
                if (!unmounted && !observing) {
                    if (deep) target.observeDeep(onChange)
                    else target.observe(onChange)
                    observing = true
                }
            }
        }
        void observe()
        void onChange()

        return () => {
            unmounted = true
            if (observing && !target.doc?.isDestroyed) target.unobserve(onChange)
        }
    }, [editing, target, deep])

    return target
}
