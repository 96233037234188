import { useEffect, useState } from "react"

/** Reflects the state after it has been stable for the specified number of milliseconds. */
export function useDebounce<T>(
    state: T /** How many milliseconds the signal must be idle before we fire. */,
    milliseconds = 500,
    /** Whether the debounce is currently suspended. It will not fire until un-suspened. */
    suspend = false,
    /**
     * The initial state to use before the first debounce. If not provided, the
     * initial state will be the same as the current state.
     */
    initialState?: T
) {
    const [debounced, setDebounced] = useState(initialState !== undefined ? initialState : state)
    useEffect(() => {
        if (suspend) return
        const timeout = setTimeout(() => setDebounced(state), milliseconds)
        return () => clearTimeout(timeout)
    }, [state, milliseconds, suspend])
    return debounced
}

/** Same as `useDebounce`, but observes the JSON-stringified content of the provided object instead
 * of the reference. */
export function useDebounceContent<T>(
    object: T,
    /** How many milliseconds the signal must be idle before we fire. */
    milliseconds = 500,
    /** Whether the debounce is currently suspended. It will not fire until un-suspened. */
    suspend = false
): T {
    const str = JSON.stringify(object)
    const res = useDebounce(str, milliseconds, suspend)
    if (res === str) return object
    else return JSON.parse(res)
}
