import { useEffect, useState } from "react"

/** Allows you to use a class to manage the state of a component, instead of React hooks.
 *
 *  This is useful when the state of a component grows too complex to reason about in regular React
 *  hooks code, and e.g. you have async side effects (such as resource loading) that you want more
 *  precise control over than what `useState` can offer.
 */
export function useStateContainer<T>(
    /** A function that creates the state container. Will be called once for the lifetime of the
     * component. Returns an object that reflects a piece of state. */
    create: (
        /** A callback that can be used to re-render the component imperatively. */
        invalidate: () => void
    ) => T & {
        /** An optional callback that will be caled when the state container is
         * destroyed.
         *
         * Note that this callback can not change over the lifetime of the
         * component, the first version of this value will be used.
         *  */
        destroy?: () => void
    }
) {
    const [, setVersion] = useState({})
    const [state] = useState(() => create(() => setVersion({})))

    if (state.destroy) {
        // This effect will run when the component is unmounted. The
        // documentation requires `state.destroy` to be stable.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(
            () => {
                return () => {
                    state.destroy!()
                }
            },
            // Intentional empty dependency array, we want this effect to run once
            // when the component is unmounted.
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        )
    }

    return state
}
