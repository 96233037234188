import { useSessionState } from "../../../reactor/Web/usePreference"
import { GetWebPermissionsDto, useWebPermissions } from "../../../studio/client"

export function EnvironmentSticker(props: { permissions?: GetWebPermissionsDto }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const permissions = props.permissions ?? useWebPermissions().data
    const env = permissions?.environment

    const [hideEnvironmentSticker, setHideEnvironmentSticker] = useSessionState(
        "hideEnvironmentSticker",
        false
    )
    return env && env !== "production" && !hideEnvironmentSticker ? (
        <div
            onClick={() => {
                alert(
                    "This will hide the environment sticker for this session. Close the tab and reopen to see it again."
                )
                setHideEnvironmentSticker(true)
            }}
            style={{
                position: "absolute",
                left: 0,
                top: 0,
                backgroundColor: env === "staging" ? "yellow" : "skyblue",
                width: 90,
                zIndex: 1000,
                textAlign: "center",
                transform: "translate(-25%,70%) rotate(-45deg)",
                fontSize: 12,
            }}
        >
            {env.toUpperCase()}
        </div>
    ) : null
}
