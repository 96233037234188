import { Uuid } from "../Types/Primitives/Uuid"

export type ResourceNotificationType =
    /**
     * Represents an incremental change to a resource. This means the change can
     * be obtained by requesting and applying new layer updates from the server.
     * No reset or backtracking is required.
     */
    | "change"
    /**
     * Represents a change that requires a reset of the resource. This means the
     * update history has been altered, and the resource must be reset to the
     * latest version and discard any uncommitted changes.
     */
    | "discard"
    /**
     * Represents that a resource has been saved, i.e. that the changes have
     * been moved from the specified layer to the base layer. Information has
     * not been lost, and the client can continue to work on the resource. No
     * new changes are expected as a result of this notification alone.
     */
    | "save"

export function IsResourceNotificationType(value: string): value is ResourceNotificationType {
    return value === "change" || value === "discard" || value === "save"
}

/**
 * A function that can be used to receive change callbacks from a resource.
 *
 * A resource can be a collection, or a GET/PUT endopint pair
 *
 * The key is provided if the change is specific to a single resource or
 * document. If the key is undefined, the change is a general change to all keys
 * in the resource.
 */
export type ResourceNotificationCallback = (
    /**
     * The name of the resource.
     *
     * For collections, this is the name of the collection.
     * For endpoints, this is the name of the GET-endpoint.
     */
    resource: string,
    /**
     * The key of the resource that changed.
     *
     * For collections, this is the primary key of the document that changed.
     *
     * For endpoints, this is the JSON-stringified argument list to the GET-endpoint.
     *
     * If not provided, the change is a general change to the resource.
     */
    key: string | undefined,

    /**
     * The layer the change occurred in. If the change was committed to the base
     * layer, this is `undefined`.
     *
     * - "base" is the default layer, and reflects changes to the underlaying
     *   resource.
     *
     * - "draft" is the default editing layer, which contains canditate changes
     *   for publishing.
     * */
    layer: string | undefined,

    /**
     * The client that made the change, if known.
     *
     * This can be used to filter out changes made by the current client.
     */
    clientId?: Uuid,

    /**
     * What type of change occurred.
     *
     * Defaults to "change" of not provided.
     */
    type?: ResourceNotificationType
) => void
