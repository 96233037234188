import React from "react"
import { useNavigate } from "../hooks/useNavigate"
import { ImageOptions, ImageToUrl } from "../../reactor/Types/File"
import { server } from "../../server"
import { useObserveYProxy } from "../y/useObserveYProxy"

export function EditableImage<T, TKey extends keyof T>({
    obj,
    prop,
    style,
    className,
    children,
    options,
    innerRef,
    link,
}: {
    obj: T
    prop: TKey
    style?: React.CSSProperties
    className?: string
    children?: React.ReactNode
    options: ImageOptions
    innerRef?: any
    link?: string
}) {
    const value = obj[prop] as any
    const nav = useNavigate()

    useObserveYProxy(obj)

    return (
        <div
            ref={innerRef}
            onClick={link ? () => nav(link) : undefined}
            className={className}
            style={{
                backgroundImage: value
                    ? "url(" + ImageToUrl(value, options) + ")"
                    : PlaceholderImageCSS(),
                backgroundSize: "cover",
                cursor: link ? "pointer" : undefined,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                ...style,
            }}
        >
            {children}
        </div>
    )
}

export const PlaceholderImageUrl = () => `${server()}/static/placeholder.png`
export const PlaceholderImageCSS = () => `url(${PlaceholderImageUrl()})`
