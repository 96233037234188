import { GetType, SubstituteAndDiscriminate } from "../../reactor/ReflectionInfo"
import { Type, GetTypeProps, IsArrayType, GetTypeAlias } from "../../reactor/Types/Type"
import { YEditor } from "../y/YEditor"
import { GetYProxyTarget } from "../y/YProxyTarget"
import * as Y from "yjs"
import { YTools } from "../y/YTools"

type Parent = { name: string; obj: any; type: Type; putType?: Type }

export type EditableResource = {
    query: string
    endpoint: string
    data: any
    dtoName: string
    putDtoName: string
}

export function GetParent(resources: YEditor<any>[], obj: any): Parent | undefined {
    function search(name: string | number, type: Type, n: any, putType?: Type): any {
        type = SubstituteAndDiscriminate(YTools.valueToJSON(GetYProxyTarget(n)), type)

        if (typeof name === "number") {
            const titleProp = GetTypeProps(type).find((p) => p.tags?.title)
            if (titleProp) {
                const title = n[titleProp.name]
                if (typeof title === "string") {
                    name = title
                }
            }
        }
        if (typeof name === "number") {
            name = GetTypeAlias(type) ?? name.toString()
        }

        const target = (typeof n === "object" ? GetYProxyTarget(n) : undefined) ?? n

        if (target instanceof Y.Array) {
            if (!IsArrayType(type)) {
                return
            }

            for (let i = 0; i < n.length; i++) {
                if (n[i] === obj) return { name, obj: n, type, putType }
                const parent = search(i, type.array, n[i])
                if (parent) return parent
            }
        } else if (target instanceof Y.Map) {
            const props = GetTypeProps(type)
            for (const prop of props) {
                const value = n[prop.name]
                if (value === obj) return { name, obj: n, type, putType }

                if (value) {
                    const parent = search(prop.name, prop.type, value)
                    if (parent) return parent
                }
            }
        }
    }
    for (const resource of resources) {
        const result = search(
            resource.res,
            GetType(resource.typeName),
            resource.proxy,
            resource.putTypeName ? GetType(resource.putTypeName) : undefined
        )
        if (result) return result
    }
}

export function GetAncestors(resources: YEditor<any>[], obj: any): Parent[] {
    const ancestors: Parent[] = []
    let current = obj
    while (current) {
        const res = GetParent(resources, current)
        if (res) ancestors.unshift(res)
        current = res?.obj
    }
    return ancestors
}
